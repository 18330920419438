import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import "../../../css/backImages.css";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 5 }}>{children}</Box>}
    </div>
  );
}

const imagesSet: Record<number, string[]> = {
  0: [
    "./Boos/1.jpg",
    "./Boos/2.jpg",
    "./Boos/3.jpg",
    "./Boos/4.jpg",
    "./Boos/5.jpg",
  ],
  1: [
    "./Slide2/1.jpg",
    "./Slide2/2.jpg",
    "./Slide2/3.jpg",
    "./Slide2/4.jpg",
    "./Slide2/5.jpg",
    "./Slide2/6.jpg",
    "./Slide2/7.jpg",
  ],
  2: [
    "./Slide4/1.jpg",
    "./Slide4/2.jpg",
    "./Slide4/3.jpg",
    "./Slide4/4.jpg",
    "./Slide4/5.jpg",
    "./Slide4/6.jpg",
    "./Slide4/7.jpg",
    "./Slide4/8.jpg",
    "./Slide4/9.jpg",
    "./Slide4/10.jpg",
  ],
  3: [
    "./Slide5/1.jpg",
    "./Slide5/2.jpg",
    "./Slide5/3.jpg",
    "./Slide5/4.jpg",
    "./Slide5/5.jpg",
    "./Slide5/6.jpg",
    "./Slide5/7.jpg",
    "./Slide5/8.jpg",
    "./Slide5/9.jpg",
    "./Slide5/10.jpg",
  ],
  4: [
    "./Slide3/1.jpg",
    "./Slide3/2.jpg",
    "./Slide3/3.jpg",
    "./Slide3/4.jpg",
    "./Slide3/5.jpg",
    "./Slide3/6.jpg",
    "./Slide3/7.jpg",
    "./Slide3/8.jpg",
    "./Slide3/9.jpg",
    "./Slide3/10.jpg",
  ],
};

export default function BackgroundImages() {
  const [value, setValue] = useState(0); // active tab
  const [currentIndex, setCurrentIndex] = useState(0); // current image index for each tab

  const nextSlide = () => {
    const currentImages = imagesSet[value];
    setCurrentIndex((prevIndex) =>
      prevIndex === currentImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    const currentImages = imagesSet[value];
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? currentImages.length - 1 : prevIndex - 1
    );
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
    setCurrentIndex(0); // reset to the first image whenever tab changes
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="img-config">
      <div className="mainImg">
        <img
          className="img-dis-none-card"
          style={{ position: "absolute", left: 0, top: "35%" }}
          src="./Icons/excoGirl.png"
          alt=""
          draggable={false}
        />
        <img
          className="img-dis-none-card"
          style={{ position: "absolute", right: "5%", bottom: "5%" }}
          src="./Icons/exco.png"
          alt=""
          draggable={false}
        />
        <div className={"logotext"}>
          <div style={{ gap: "9px" }}>
            <img src="./Icons/tablogo2.svg" alt="" draggable={false} />
            <img src="./Icons/tablogo.svg" alt="" draggable={false} />
          </div>
          <span>뉴테크 공모전의 분위기를 볼 수 있어요!</span>
        </div>
        <Box className={"btn-six-row"}>
          <Button
            className={"tabButton"}
            onClick={() => handleChange(0)}
            style={{
              background:
                value === 0 ? "rgba(255, 198, 0, 1)" : "rgba(0, 184, 96, 1)",
              color:
                value === 0 ? "rgba(255, 255, 255, 1)" : "rgba(60, 60, 60, 1)",
              marginRight: 12,
            }}
          >
            #부스
          </Button>
          <Button
            className={"tabButton"}
            onClick={() => handleChange(1)}
            style={{
              background:
                value === 1 ? "rgba(255, 198, 0, 1)" : "rgba(0, 184, 96, 1)",
              color:
                value === 1 ? "rgba(255, 255, 255, 1)" : "rgba(60, 60, 60, 1)",
              marginRight: 12,
            }}
          >
            #공모전
          </Button>
          <Button
            className={"tabButton"}
            onClick={() => handleChange(2)}
            style={{
              background:
                value === 2 ? "rgba(255, 198, 0, 1)" : "rgba(0, 184, 96, 1)",
              color:
                value === 2 ? "rgba(255, 255, 255, 1)" : "rgba(60, 60, 60, 1)",
              marginRight: 12,
            }}
          >
            #초등부
          </Button>
          <Button
            className={"tabButton"}
            onClick={() => handleChange(3)}
            style={{
              background:
                value === 3 ? "rgba(255, 198, 0, 1)" : "rgba(0, 184, 96, 1)",
              color:
                value === 3 ? "rgba(255, 255, 255, 1)" : "rgba(60, 60, 60, 1)",
              marginRight: 12,
            }}
          >
            #중고등
          </Button>
          <Button
            className={"tabButton"}
            onClick={() => handleChange(4)}
            style={{
              background:
                value === 4 ? "rgba(255, 198, 0, 1)" : "rgba(0, 184, 96, 1)",
              color:
                value === 4 ? "rgba(255, 255, 255, 1)" : "rgba(60, 60, 60, 1)",
            }}
          >
            #시상식
          </Button>
        </Box>

        {/* Custom carousel for each tab */}
        <div className="carousel-container">
          <button className="prev-btn" onClick={prevSlide}>
            <KeyboardArrowLeftRoundedIcon fontSize="large" />
          </button>

          <div className="carousel-slide">
            <img
              src={imagesSet[value][currentIndex]}
              alt={`Slide ${currentIndex}`}
              draggable={false}
            />
          </div>

          <button className="next-btn" onClick={nextSlide}>
            <KeyboardArrowRightRoundedIcon fontSize="large" />
          </button>
        </div>

        <div className="pagination-dots">
          {imagesSet[value].map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
}
