import React, { useState, useRef, useEffect } from "react";
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import "../../../css/allVideos.css";

export default function AllMain() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [itemsPerPage, setItemsPerPage] = useState(16);

  const navigate = useNavigate();

  const handleVideoClick = (url: string, index: number) => {
    setSelectedVideo(url);
    setActiveIndex(index);
    if (iframeContainerRef.current) {
      iframeContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentVideos = videos.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setItemsPerPage(12);
      } else if (window.innerWidth <= 1024) {
        setItemsPerPage(15);
      } else {
        setItemsPerPage(16);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="all-main-container">
      <div className="content-center">
        <div className="video-container">
          <div
            ref={iframeContainerRef}
            className={`video-frame ${selectedVideo ? "visible" : ""}`}
            style={{
              backgroundImage: backgroundImage ? backgroundImage : undefined,
            }}
          >
            {selectedVideo && (
              <iframe
                id="vid_frame"
                src={selectedVideo}
                className="iframe"
                allowFullScreen
                scrolling="no"
                allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>

          <div className="main-titLogo">
            <div className="all-titLogo">
              <img
                className="logo-img"
                src="./Icons/mzTable.svg"
                alt=""
                draggable={false}
              />
              <img
                className="title-img"
                src="./Icons/mzTitle.svg"
                alt=""
                draggable={false}
              />
            </div>
            <div>
              <span>
                <b>MZ</b>새싹캠프에 참여한 학생들의 멋진 작품을 볼 수 있어요!
              </span>
            </div>
          </div>
          <div className="video-thumbnails">
            {currentVideos.map((url, index) => {
              const videoId = url.split("/").pop();
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

              const reverseIndex = videos.length - (indexOfFirstItem + index);
              const formattedNumber = reverseIndex.toString().padStart(3, "0");

              return (
                <div className="video-thumbnail" key={index}>
                  <section id="extra-wrapper">
                    <div className="thumbVid2">
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          handleVideoClick(url, indexOfFirstItem + index);
                        }}
                      >
                        <div className="thumbnail-content">
                          <div
                            className="vid-thumb"
                            style={{
                              backgroundImage: `url(${thumbnailUrl})`,
                              backgroundPosition: "center",
                              backgroundSize: "100%",
                            }}
                          ></div>
                          <div
                            className={`secondST ${
                              activeIndex === indexOfFirstItem + index
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                activeIndex === indexOfFirstItem + index
                                  ? "#2F96FD"
                                  : "transparent",
                            }}
                          >
                            <p
                              className="secondST-text"
                              style={{
                                color:
                                  activeIndex === indexOfFirstItem + index
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            >
                              {name[indexOfFirstItem + index]}
                            </p>
                            <div>
                              <p
                                className={"secondST-description"}
                                style={{
                                  color:
                                    activeIndex === indexOfFirstItem + index
                                      ? "#FFFFFF"
                                      : "#000000",
                                }}
                              >
                                {formattedNumber}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </section>
                </div>
              );
            })}
          </div>

          <div className="Cpag">
            <Pagination
              count={Math.ceil(videos.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              className="custom-pagination"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const videos = [
  "https://www.youtube.com/embed/5GHPWL9A9uA",
  "https://www.youtube.com/embed/3fvqQhEfX0c",
  "https://www.youtube.com/embed/VQC0eeHLrt4",
  "https://www.youtube.com/embed/0mTfN96c0tw",
  "https://www.youtube.com/embed/lQ-07AYJoLM",
  "https://www.youtube.com/embed/rk1oE6ZXFL4",
  "https://www.youtube.com/embed/kMyMr-moEjs",
  "https://www.youtube.com/embed/yJ6pfcwtQTE",
  "https://www.youtube.com/embed/TK_xGcVkVUM",
  "https://www.youtube.com/embed/916aAI9kYy8",
  "https://www.youtube.com/embed/AHnUJtFqNYI",
  "https://www.youtube.com/embed/_j0aGqxOW4g",
  "https://www.youtube.com/embed/QvDDqyvFh-U",
  "https://www.youtube.com/embed/-vjG02BbVKM",
  "https://www.youtube.com/embed/wBe4O-6yaes",
  "https://www.youtube.com/embed/xqUYaM27FdA",
  "https://www.youtube.com/embed/s42oZsBg0I0",
  "https://www.youtube.com/embed/x9dNF375PAY",
  "https://www.youtube.com/embed/Ik_bWoyB5K8",
  "https://www.youtube.com/embed/xjW7GZJbBkI",
  "https://www.youtube.com/embed/UXZfMksW4I0",
  "https://www.youtube.com/embed/5XrTL7bISxs",
  "https://www.youtube.com/embed/ZnFSt0MtZ0I",
  "https://www.youtube.com/embed/Ll0N5CyPYV8",
  "https://www.youtube.com/embed/AhS8Uv0mvdQ",
  "https://www.youtube.com/embed/r7qzhuZBe9w",
  "https://www.youtube.com/embed/H_V2Npxg6aE",
  "https://www.youtube.com/embed/TerJPgI3TRk",
  "https://www.youtube.com/embed/zsXH_qMl_oQ",
  "https://www.youtube.com/embed/to-wyngO_Sk",
  "https://www.youtube.com/embed/sQ4z3friy2w",
  "https://www.youtube.com/embed/5gIgYhSfMSU",
  "https://www.youtube.com/embed/8-qs9LIcmY4",
  "https://www.youtube.com/embed/4d2SKbVxsLg",
  "https://www.youtube.com/embed/oKPC71Je7WI",
  "https://www.youtube.com/embed/wT1oDnPXifs",
  "https://www.youtube.com/embed/LtCE4H-uXAY",
  "https://www.youtube.com/embed/NexV7t5sB9M",
  "https://www.youtube.com/embed/nohbSToXits",
  "https://www.youtube.com/embed/C2swh0zupIQ",
  "https://www.youtube.com/embed/KRXGpWZcdxQ",
  "https://www.youtube.com/embed/JDhV1zJl-VE",
  "https://www.youtube.com/embed/Vsdb-904Too",
  "https://www.youtube.com/embed/8TMzJHgjGi8",
  "https://www.youtube.com/embed/xPwAEScEfjs",
  "https://www.youtube.com/embed/mgacKXgFI48",
  "https://www.youtube.com/embed/1CV-BCQnIhk",
  "https://www.youtube.com/embed/2Gaw_VyE-oQ",
  "https://www.youtube.com/embed/0j-juyARaYw",
  "https://www.youtube.com/embed/NQsEv_IKNoc",
  "https://www.youtube.com/embed/g2g0hNMibJM",
  "https://www.youtube.com/embed/3OxCMGpG_lY",
  "https://www.youtube.com/embed/lRR1YsjxkC4",
  "https://www.youtube.com/embed/I5fWUq1pa_4",
  "https://www.youtube.com/embed/rZ53Mpz3TWU",
  "https://www.youtube.com/embed/uGMhLNL0P_4",
  "https://www.youtube.com/embed/WlFdkwklEvQ",
  "https://www.youtube.com/embed/rGzh-IIRRuk",
  "https://www.youtube.com/embed/8RwESLxJ9iU",
  "https://www.youtube.com/embed/pb7DItw5VB0",
  "https://www.youtube.com/embed/C3hMEWRSloE",
  "https://www.youtube.com/embed/fDvtIKHJm3A",
  "https://www.youtube.com/embed/ZJqVR2Cp6J0",
  "https://www.youtube.com/embed/bG4lZIPkwjk",
  "https://www.youtube.com/embed/7caviI_v8Mw",
  "https://www.youtube.com/embed/b10G6EdVxU8",
  "https://www.youtube.com/embed/qqwHgSv8ni4",
  "https://www.youtube.com/embed/OH7Pgx1m8Qk",
  "https://www.youtube.com/embed/fRE-B-FPu0I",
  "https://www.youtube.com/embed/_hzR5TSlGHE",
  "https://www.youtube.com/embed/c_lRHZE64yQ",
  "https://www.youtube.com/embed/Tyjq0Njg7Ow",
  "https://www.youtube.com/embed/C_aidez-e-4",
  "https://www.youtube.com/embed/10zQ7wk14Ms",
  "https://www.youtube.com/embed/hIArPp-UDDg",
  "https://www.youtube.com/embed/Q6lAgriGmMM",
  "https://www.youtube.com/embed/oQh7DHHNa5U",
  "https://www.youtube.com/embed/Ly_t5pigC3I",
  "https://www.youtube.com/embed/06kRvuMvToU",
  "https://www.youtube.com/embed/VFEjHaEDFUM",
  "https://www.youtube.com/embed/BPB0KEz4xfQ",
  "https://www.youtube.com/embed/XlDDUa9bI7c",
  "https://www.youtube.com/embed/6FTV0LHXths",
  "https://www.youtube.com/embed/8UPAynTElDA",
  "https://www.youtube.com/embed/Uwpn72RTwpE",
  "https://www.youtube.com/embed/5GSL9diBEGE",
  "https://www.youtube.com/embed/yWQGvpHU7mA",
  "https://www.youtube.com/embed/q4O8RweZ8Jo",
  "https://www.youtube.com/embed/tV6FPhHg6yk",
  "https://www.youtube.com/embed/abi_itVw2Oc",
  "https://www.youtube.com/embed/O9gpcNCu_mE",
  "https://www.youtube.com/embed/UWY0x2uE2gw",
  "https://www.youtube.com/embed/n7ljHf7Mb_c",
  "https://www.youtube.com/embed/LxffsOswi3w",
  "https://www.youtube.com/embed/g-bJ5nnLhN4",
  "https://www.youtube.com/embed/oXWfGB4PBLE",
  "https://www.youtube.com/embed/ni6rBw5lh60",
  "https://www.youtube.com/embed/kGopKcdbyTw",
  "https://www.youtube.com/embed/Xvwl9tLPAyc",
  "https://www.youtube.com/embed/ZNoH_OC6ACw",
  "https://www.youtube.com/embed/5EfVcEqq7Gg",
  "https://www.youtube.com/embed/L4Es2OSIktk",
  "https://www.youtube.com/embed/mnr3JPTEmW0",
  "https://www.youtube.com/embed/Ssnp90J5wfM",
  "https://www.youtube.com/embed/8J0KGfAupMs",
  "https://www.youtube.com/embed/UB63ZMNyfyY",
  "https://www.youtube.com/embed/IXStmZK3zts",
  "https://www.youtube.com/embed/Ds_Jo9pPVxE",
  "https://www.youtube.com/embed/gII7rHygqus",
  "https://www.youtube.com/embed/_Pmw9t4jGlQ",
  "https://www.youtube.com/embed/PyTeq0JBQr0",
  "https://www.youtube.com/embed/VGU8Cjzhtjs",
  "https://www.youtube.com/embed/YDKpFuDu9cY",
  "https://www.youtube.com/embed/HTABY9uMKk0",
  "https://www.youtube.com/embed/yLgM6sUFosE",
  "https://www.youtube.com/embed/oEWZTuCNY7E",
  "https://www.youtube.com/embed/-6zE74lrk_8",
  "https://www.youtube.com/embed/ud87GWvXxjU",
  "https://www.youtube.com/embed/BnEw0FVpo1g",
  "https://www.youtube.com/embed/UoGRKZ0gsZk",
  "https://www.youtube.com/embed/zBFPKM9XRPw",
  "https://www.youtube.com/embed/GjmSe9WMwaA",
];

const name = [
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[하이텍고등학교 1학년]",
  "[효동초등학교 4학년]",
  "[효동초등학교 4학년]",
  "[효동초등학교 4학년]",
  "[효동초등학교 4학년]",
  "[효동초등학교 4학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[아양초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[와룡초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[산격초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
  "[율원초등학교 5학년]",
];
