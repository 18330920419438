import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const styleFirstWord = (text: string, color: any) => {
  const words = text.split(" ");
  return (
    <span>
      <span style={{ color }}>{words[0]}</span>
      {` ${words.slice(1).join(" ")}`}
    </span>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 900,
  height: "auto",
  maxHeight: 640,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "30px",
  p: 4,
  overflow: "hidden",
};

const images = [
  { id: 1, src: "/School/1.png", alt: "Image 1" },
  { id: 2, src: "/School/2.png", alt: "Image 2" },
  { id: 3, src: "/School/3.png", alt: "Image 3" },
  { id: 4, src: "/School/4.png", alt: "Image 4" },
  { id: 5, src: "/School/5.png", alt: "Image 5" },
  { id: 6, src: "/School/6.png", alt: "Image 6" },
  { id: 7, src: "/School/7.png", alt: "Image 7" },
  { id: 8, src: "/School/8.png", alt: "Image 8" },
  { id: 9, src: "/School/9.png", alt: "Image 9" },
  { id: 10, src: "/School/10.png", alt: "Image 10" },
];
const images2 = [
  { id: 1, src: "/1/1.jpg", alt: "Image 1" },
  { id: 1, src: "/1/2.jpg", alt: "Image 1" },
  { id: 1, src: "/1/3.jpg", alt: "Image 1" },
  { id: 1, src: "/1/4.jpg", alt: "Image 1" },
  { id: 1, src: "/1/5.jpg", alt: "Image 1" },
  { id: 1, src: "/1/6.jpg", alt: "Image 1" },
  { id: 1, src: "/1/7.jpg", alt: "Image 1" },
  { id: 1, src: "/1/8.jpg", alt: "Image 1" },
  { id: 1, src: "/1/9.jpg", alt: "Image 1" },
];

const imagesMap: { [key: number]: { id: number; src: string; alt: string }[] } =
  {
    1: [
      { id: 1, src: "/1/1.jpg", alt: "Image 1" },
      { id: 1, src: "/1/2.jpg", alt: "Image 1" },
      { id: 1, src: "/1/3.jpg", alt: "Image 1" },
      { id: 1, src: "/1/4.jpg", alt: "Image 1" },
      { id: 1, src: "/1/5.jpg", alt: "Image 1" },
      { id: 1, src: "/1/6.jpg", alt: "Image 1" },
      { id: 1, src: "/1/7.jpg", alt: "Image 1" },
      { id: 1, src: "/1/8.jpg", alt: "Image 1" },
      { id: 1, src: "/1/9.jpg", alt: "Image 1" },
    ],
    2: [
      { id: 2, src: "/2/1.jpg", alt: "Image 1" },
      { id: 2, src: "/2/2.jpg", alt: "Image 1" },
      { id: 2, src: "/2/3.jpg", alt: "Image 1" },
      { id: 2, src: "/2/4.jpg", alt: "Image 1" },
      { id: 2, src: "/2/5.jpg", alt: "Image 1" },
      { id: 2, src: "/2/6.jpg", alt: "Image 1" },
      { id: 2, src: "/2/7.jpg", alt: "Image 1" },
      { id: 2, src: "/2/8.jpg", alt: "Image 1" },
      { id: 2, src: "/2/9.jpg", alt: "Image 1" },
    ],
    3: [
      { id: 3, src: "/3/9.jpg", alt: "Image 1" },
      { id: 3, src: "/3/2.jpg", alt: "Image 1" },
      { id: 3, src: "/3/3.jpg", alt: "Image 1" },
      { id: 3, src: "/3/4.jpg", alt: "Image 1" },
      { id: 3, src: "/3/5.jpg", alt: "Image 1" },
      { id: 3, src: "/3/6.jpg", alt: "Image 1" },
      { id: 3, src: "/3/7.jpg", alt: "Image 1" },
      { id: 3, src: "/3/8.jpg", alt: "Image 1" },
      { id: 3, src: "/3/1.jpg", alt: "Image 1" },
    ],
    4: [
      { id: 4, src: "/4/1.jpg", alt: "Image 1" },
      { id: 4, src: "/4/2.jpg", alt: "Image 1" },
      { id: 4, src: "/4/3.jpg", alt: "Image 1" },
      { id: 4, src: "/4/4.jpg", alt: "Image 1" },
      { id: 4, src: "/4/5.jpg", alt: "Image 1" },
      { id: 4, src: "/4/6.jpg", alt: "Image 1" },
      { id: 4, src: "/4/7.jpg", alt: "Image 1" },
      { id: 4, src: "/4/8.jpg", alt: "Image 1" },
      { id: 4, src: "/4/9.jpg", alt: "Image 1" },
      { id: 4, src: "/4/10.jpg", alt: "Image 1" },
      { id: 4, src: "/4/11.jpg", alt: "Image 1" },
      { id: 4, src: "/4/12.jpg", alt: "Image 1" },
    ],
    5: [
      { id: 5, src: "/5/1.jpg", alt: "Image 1" },
      { id: 5, src: "/5/2.jpg", alt: "Image 1" },
      { id: 5, src: "/5/3.jpg", alt: "Image 1" },
      { id: 5, src: "/5/4.jpg", alt: "Image 1" },
      { id: 5, src: "/5/5.jpg", alt: "Image 1" },
      { id: 5, src: "/5/6.jpg", alt: "Image 1" },
      { id: 5, src: "/5/7.jpg", alt: "Image 1" },
      { id: 5, src: "/5/8.jpg", alt: "Image 1" },
      { id: 5, src: "/5/9.jpg", alt: "Image 1" },
      { id: 5, src: "/5/10.jpg", alt: "Image 1" },
      { id: 5, src: "/5/11.jpg", alt: "Image 1" },
      { id: 5, src: "/5/12.jpg", alt: "Image 1" },
    ],
    6: [
      { id: 6, src: "/6/1.jpg", alt: "Image 1" },
      { id: 6, src: "/6/2.jpg", alt: "Image 1" },
      { id: 6, src: "/6/3.jpg", alt: "Image 1" },
      { id: 6, src: "/6/4.jpg", alt: "Image 1" },
      { id: 6, src: "/6/5.jpg", alt: "Image 1" },
      { id: 6, src: "/6/6.jpg", alt: "Image 1" },
      { id: 6, src: "/6/7.jpg", alt: "Image 1" },
      { id: 6, src: "/6/8.jpg", alt: "Image 1" },
      { id: 6, src: "/6/9.jpg", alt: "Image 1" },
      { id: 6, src: "/6/10.jpg", alt: "Image 1" },
      { id: 6, src: "/6/11.jpg", alt: "Image 1" },
      { id: 6, src: "/6/12.jpg", alt: "Image 1" },
    ],
    7: [
      { id: 7, src: "/7/1.jpg", alt: "Image 1" },
      { id: 7, src: "/7/2.jpg", alt: "Image 1" },
      { id: 7, src: "/7/3.jpg", alt: "Image 1" },
      { id: 7, src: "/7/4.jpg", alt: "Image 1" },
      { id: 7, src: "/7/5.jpg", alt: "Image 1" },
      { id: 7, src: "/7/6.jpg", alt: "Image 1" },
      { id: 7, src: "/7/7.jpg", alt: "Image 1" },
      { id: 7, src: "/7/8.jpg", alt: "Image 1" },
      { id: 7, src: "/7/9.jpg", alt: "Image 1" },
      { id: 7, src: "/7/10.jpg", alt: "Image 1" },
      { id: 7, src: "/7/11.jpg", alt: "Image 1" },
      { id: 7, src: "/7/12.jpg", alt: "Image 1" },
    ],
    8: [
      { id: 8, src: "/8/1.jpg", alt: "Image 1" },
      { id: 8, src: "/8/2.jpg", alt: "Image 1" },
      { id: 8, src: "/8/3.jpg", alt: "Image 1" },
      { id: 8, src: "/8/4.jpg", alt: "Image 1" },
      { id: 8, src: "/8/5.jpg", alt: "Image 1" },
      { id: 8, src: "/8/6.jpg", alt: "Image 1" },
      { id: 8, src: "/8/7.jpg", alt: "Image 1" },
      { id: 8, src: "/8/8.jpg", alt: "Image 1" },
      { id: 8, src: "/8/9.jpg", alt: "Image 1" },
    ],
    9: [
      { id: 9, src: "/9/1.jpg", alt: "Image 1" },
      { id: 9, src: "/9/2.jpg", alt: "Image 1" },
      { id: 9, src: "/9/3.jpg", alt: "Image 1" },
      { id: 9, src: "/9/4.jpg", alt: "Image 1" },
      { id: 9, src: "/9/5.jpg", alt: "Image 1" },
      { id: 9, src: "/9/6.jpg", alt: "Image 1" },
      { id: 9, src: "/9/7.jpg", alt: "Image 1" },
    ],
    10: [
      { id: 10, src: "/10/1.jpg", alt: "Image 1" },
      { id: 10, src: "/10/2.jpg", alt: "Image 1" },
      { id: 10, src: "/10/3.jpg", alt: "Image 1" },
      { id: 10, src: "/10/4.jpg", alt: "Image 1" },
      { id: 10, src: "/10/5.jpg", alt: "Image 1" },
      { id: 10, src: "/10/6.jpg", alt: "Image 1" },
      { id: 10, src: "/10/7.jpg", alt: "Image 1" },
      { id: 10, src: "/10/8.jpg", alt: "Image 1" },
      { id: 10, src: "/10/9.jpg", alt: "Image 1" },
      { id: 10, src: "/10/10.jpg", alt: "Image 1" },
      { id: 10, src: "/10/11.jpg", alt: "Image 1" },
      { id: 10, src: "/10/12.jpg", alt: "Image 1" },
    ],
  };

const titles = [
  "호산초등학교",
  "와룡초등학교",
  "범일초등학교",
  "율원초등학교",
  "입석초등학교",
  "아양초등학교",
  "산격초등학교",
  "효동초등학교",
  "반송초등학교",
  "대구하이텍고등학교",
];

const date = [
  "교육날짜 2023-12-04,05",
  "교육날짜 2023-12-18",
  "교육날짜 2023-12-11",
  "교육날짜 2023-12-12",
  "교육날짜 2023-12-08,15",
  "교육날짜 2023-12-19",
  "교육날짜 2023-12-11",
  "교육날짜 2023-01-04,29",
  "교육날짜 2023-12-07",
  "교육날짜 2023-12-19",
];

const grade = [
  "학년 초등6학년",
  "학년 초등5학년",
  "학년 초등5학년",
  "학년 초등5학년",
  "학년 초등5학년",
  "학년 초등5학년",
  "학년 초등5학년",
  "학년 초등4학년",
  "학년 초등6학년",
  "학년 고등1학년",
];

const person = [
  "참여인원 12명",
  "참여인원 75명",
  "참여인원 22명",
  "참여인원 138명",
  "참여인원 38명",
  "참여인원 46명",
  "참여인원 64명",
  "참여인원 10명",
  "참여인원 15명",
  "참여인원 8명",
];

const time = [
  "교육시간 총 8시간",
  "교육시간 총 6시간",
  "교육시간 총 8시간",
  "교육시간 총 12시간",
  "교육시간 총 8시간",
  "교육시간 총 8시간",
  "교육시간 총 6시간",
  "교육시간 총 8시간",
  "교육시간 총 6시간",
  "교육시간 총 8시간",
];

export function Photos() {
  const [open, setOpen] = useState(false);
  const [mainImage, setMainImage] = useState(images2[0]);
  const [currentImages, setCurrentImages] = useState(images2);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOpen = (index: number) => {
    setSelectedIndex(index);
    if (imagesMap[images[index].id]) {
      setCurrentImages(imagesMap[images[index].id]);
      setMainImage(imagesMap[images[index].id][0]);
    } else {
      setCurrentImages(images);
      setMainImage(images[index]);
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="pho-config">
      <div className="pho-config2">
        <img
          className="display-none"
          style={{ position: "absolute", left: "5%", bottom: "250px" }}
          src="./Icons/paint.png"
          alt=""
          draggable={false}
        />
        <img
          className="display-none2"
          style={{ position: "absolute", right: "2%", bottom: 0 }}
          src="./Icons/paint2.png"
          alt=""
          draggable={false}
        />
        <img
          className="img-lenta-photo"
          src="./Image/Union.svg"
          alt=""
          draggable={false}
        />
        <div className="logotext">
          <div style={{ display: "flex", flexDirection: "row", gap: "9px" }}>
            <img
              style={{ width: "52px", height: "52px" }}
              src="./Icons/bag.svg"
              alt=""
              draggable="false"
            />
            <img
              style={{ width: "auto", height: "45px" }}
              src="./Icons/text.svg"
              alt=""
              draggable="false"
            />
          </div>
          <span>
            <b>MZ</b>새싹캠프에 참여한 학교들의 활동 사진을 볼 수 있어요!
          </span>
        </div>
        <div className="mainpho">
          <div className="photo-respon-mob-card">
            {images.slice(0, 4).map((image, index) => (
              <div
                key={image.id}
                className="pho-btn"
                onClick={() => handleOpen(index)} // Pass index to handleOpen
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <img src={image.src} alt={image.alt} draggable={false} />
                <div className="btnFoot">{titles[index]}</div>
              </div>
            ))}
          </div>
          <div className="photo-respon-mob-card">
            {images.slice(4, 8).map((image, index) => (
              <div
                key={image.id}
                className="pho-btn"
                onClick={() => handleOpen(index + 4)} // Adjust index to match the sliced array
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <img src={image.src} alt={image.alt} draggable={false} />
                <div className="btnFoot">{titles[index + 4]}</div>
              </div>
            ))}
          </div>
          <div className="photo-respon-mob-card">
            {images.slice(8).map((image, index) => (
              <div
                key={image.id}
                className="pho-btn"
                onClick={() => handleOpen(index + 8)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <img src={image.src} alt={image.alt} draggable={false} />
                <div className="btnFoot">{titles[index + 8]}</div>
              </div>
            ))}
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={"style-css-box"} sx={style}>
            <div className={"vector"}>
              <img
                src="./Icons/Vector.png"
                alt=""
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                draggable={false}
              />
            </div>
            <div className="titCon">
              <span className={"titleModal"} style={{ marginLeft: "10px" }}>
                <b style={{ color: "rgba(0, 0, 0, 1)" }}>
                  {titles[selectedIndex]}
                </b>
              </span>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "1px",
                  background: "rgba(158, 158, 158, 1)",
                  marginTop: "8px",
                }}
              ></div>
              <div className="titdate">
                <p className={"date"}>
                  <span style={{ color: "#00D674" }}>
                    {date[selectedIndex].split(" ")[0]}
                  </span>
                  {` ${date[selectedIndex].split(" ")[1]}`}
                </p>
                <p className={"time"}>
                  <span style={{ color: "#00D674" }}>
                    {time[selectedIndex].split(" ")[0]}
                  </span>
                  {` ${time[selectedIndex].split(" ").slice(1).join(" ")}`}
                </p>
                <p className={"person"}>
                  <span style={{ color: "#00D674" }}>
                    {person[selectedIndex].split(" ")[0]}
                  </span>
                  {` ${person[selectedIndex].split(" ").slice(1).join(" ")}`}
                </p>
                <p className={"grade"}>
                  <span style={{ color: "#00D674" }}>
                    {grade[selectedIndex].split(" ")[0]}
                  </span>
                  {` ${grade[selectedIndex].split(" ").slice(1).join(" ")}`}
                </p>
              </div>
            </div>
            <div
              className="thumbImg"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={mainImage.src}
                alt={mainImage.alt}
                className="main-thumb-image"
                draggable={false}
                style={{ width: "70%", height: "87%" }}
              />
              <div className="thumbnail-con-image">
                {currentImages.map((image) => (
                  <img
                    key={image.id}
                    src={image.src}
                    alt={image.alt}
                    className="thumbnail-image"
                    onClick={() => setMainImage(image)}
                    draggable={false}
                    style={{}}
                  />
                ))}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
