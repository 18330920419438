import React, { useEffect, useState } from "react";
import { Photos } from "./Photos";
import { Emoticon } from "./Emoticons";
import VideoGallery from "./YouTube";
import BackgroundImages from "./BackImages";
import Competition from "./Competition";
import Footer from "../../components/footer";
import { NavbarHome } from "../../components/header";

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <NavbarHome />
      <Photos />
      <Emoticon />
      <VideoGallery />
      <BackgroundImages />
      <Competition />
      <Footer />
    </div>
  );
};

export default HomePage;
