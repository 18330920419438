import React, { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { IconButton } from "@mui/material";
import "../../../css/head.css";

const videos = [
  "https://www.youtube.com/embed/MFDQPK8olSk",
  "https://www.youtube.com/embed/7I-grCb0Fcw",
  "https://www.youtube.com/embed/r_Wq-rjFoN0",
  "https://www.youtube.com/embed/a3LQDx9xmYo",
  "https://www.youtube.com/embed/NsiVALU2IbE",
  "https://www.youtube.com/embed/Fk56XpRmWZU",
  "https://www.youtube.com/embed/Jbfpkza4TX0",
  "https://www.youtube.com/embed/I8LYXFBfFfM",
  "https://www.youtube.com/embed/5fdn4IL5k9U",
  "https://www.youtube.com/embed/c04rnJRtHlw",
  "https://www.youtube.com/embed/AHnUJtFqNYI",
  "https://www.youtube.com/embed/_j0aGqxOW4g",
  "https://www.youtube.com/embed/AE8LN25z3zk",
  "https://www.youtube.com/embed/Jq5vTxYPyms",
  "https://www.youtube.com/embed/rtAgQYH3XMc",
  "https://www.youtube.com/embed/WacfkLLlD2o",
  "https://www.youtube.com/embed/yqaFC3k8Hxs",
  "https://www.youtube.com/embed/zYcUSsVdSPk",
  "https://www.youtube.com/embed/ena_25Iua40",
  "https://www.youtube.com/embed/4h7cRgqoPlg",
  "https://www.youtube.com/embed/14rE4jcwj3Y",
  "https://www.youtube.com/embed/bbIYOIHxdMg",
];

const name = [
  "[초등고학년 대상]",
  "[초등고학년 우수상]",
  "[초등고학년 우수상]",
  "[초등고학년 우수상]",
  "[초등고학년 최우수상]",
  "[초등고학년 최우수상]",
  "[초등저학년 대상]",
  "[초등저학년 우수상]",
  "[초등저학년 우수상]",
  "[초등저학년 우수상]",
  "[초등저학년/우수상]",
  "[초등고학년/최우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
  "[고등부/우수상]",
];

const nameTit = [
  "이★연",
  "이★운",
  "정★린",
  "최★솔",
  "김★연",
  "박★향",
  "김★린",
  "서★아",
  "이★윤",
  "정★민",
  "이★별학생",
  "최★원학생",
  "1조★1",
  "1조★2",
  "1조★3",
  "1조★4",
  "1조★5",
  "1조★6",
  "1조★7",
  "1조★8",
  "1조★9",
  "1조★10",
];

export default function Main() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLinks, setShowLinks] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeLink, setActiveLink] = useState("/other-page");
  const [selectedVideo, setSelectedVideo] = useState<string>(videos[0]);
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(16);

  const [backgroundImage, setBackgroundImage] =
    useState<string>(`url(./youtube/1.png)`);

  const navigate = useNavigate();

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShowLinks(false);
    } else {
      setShowLinks(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  const handleVideoClick = (url: string, index: number) => {
    setSelectedVideo(url);
    setActiveIndex(index);
    if (iframeContainerRef.current) {
      iframeContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentVideos = videos.slice(indexOfFirstItem, indexOfLastItem);

  const handleSetActiveLink = (link: React.SetStateAction<string>) => {
    setActiveLink(link);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      const videoThumbnails = document.querySelector(
        ".video-thumbnails"
      ) as HTMLElement;

      if (window.innerWidth <= 1024) {
        setItemsPerPage(12);
        if (videoThumbnails) {
          videoThumbnails.style.gridTemplateColumns = "repeat(2, 1fr)";
        }
      } else {
        setItemsPerPage(12);
        if (videoThumbnails) {
          videoThumbnails.style.gridTemplateColumns = "repeat(3, 1fr)";
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="head-config">
      <div id="headCon2">
        <img
          className="coronaR"
          src="./Icons/coronaR.png"
          alt=""
          draggable={false}
        />
        <img
          className="coronaL"
          src="./Icons/coronaL.png"
          alt=""
          draggable={false}
        />

        <div className="config-navbar">
          <div className="con-icon">
            <Link
              to="/"
              smooth
              onClick={() => handleSetActiveLink("#home-navbar")}
            >
              <img src="../Icons/mz.svg" alt="" draggable={false} />
            </Link>
          </div>
          <div className="menu-icon">
            <IconButton onClick={toggleMenu}>
              <MenuRoundedIcon className="menu-rounded-icon-main-video" />
            </IconButton>
          </div>

          {/* Display links based on screen size and menu state */}
          <div className={`con-link ${menuOpen ? "menu-open" : ""}`}>
            <Link
              className={`main-link ${
                activeLink === "#home-navbar" ? "active" : ""
              }`}
              to="/"
              smooth
              onClick={() => handleSetActiveLink("#home-navbar")}
            >
              소개
            </Link>
            <Link
              className={`main-link ${
                activeLink === "/all-videos" ? "active" : ""
              }`}
              to="/all-videos"
              onClick={() => handleSetActiveLink("/all-videos")}
            >
              학생작품
            </Link>
            <Link
              className={`main-link ${
                activeLink === "/other-page" ? "active" : ""
              }`}
              to="/other-page"
              onClick={() => handleSetActiveLink("/other-page")}
            >
              수상작품
            </Link>
          </div>
        </div>
        <div className="vidTitle">
          <img src="./Icons/tesang2.svg" alt="" draggable={false} />
          <span className="vidTitle-text">
            #뉴테크공모전 #대상 #초등고학년 #이★연학생 #작품
          </span>
        </div>
        <div className="HVideo">
          <div
            ref={iframeContainerRef}
            className={`HVideo2 ${!selectedVideo ? "no-video" : ""}`}
            style={{
              backgroundImage: selectedVideo ? "none" : backgroundImage,
            }}
          >
            {selectedVideo && (
              <iframe
                id="vid_frame"
                src={selectedVideo}
                className="iframe"
                allowFullScreen
                scrolling="no"
                allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div className="allVideos">
        <div className="con-vid">
          <div className="firstS">
            <div>
              <img
                className="head-img-respon-phone"
                src="./Icons/school.svg"
                alt=""
                draggable={false}
              />
              <img
                className="head-img-respon-phone2"
                src="./Icons/school2.svg"
                alt=""
                draggable={false}
              />
            </div>
            <div>
              <span>뉴테크 공모전 수상자들의 영상을 볼 수 있어요!</span>
            </div>
          </div>
          <div className="video-thumbnails">
            {currentVideos.map((url, index) => {
              const videoId = url.split("/").pop();
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

              const reverseIndex = videos.length - (indexOfFirstItem + index);
              const formattedNumber = reverseIndex.toString().padStart(3, "0");

              return (
                <div className="video-thumbnail" key={index}>
                  <section id="extra-wrapper">
                    <div className="thumbVid2">
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          handleVideoClick(url, indexOfFirstItem + index);
                        }}
                      >
                        <div className="thumbnail-content">
                          <div
                            className="vid-thumb"
                            style={{
                              backgroundImage: `url(${thumbnailUrl})`,
                              backgroundPosition: "center",
                              backgroundSize: "100%",
                            }}
                          ></div>
                          <div
                            className={`secondST ${
                              activeIndex === indexOfFirstItem + index
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                activeIndex === indexOfFirstItem + index
                                  ? "#ff7a00"
                                  : "transparent",
                            }}
                          >
                            <p
                              className="secondST-text"
                              style={{
                                color:
                                  activeIndex === indexOfFirstItem + index
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            >
                              {name[indexOfFirstItem + index]}
                            </p>
                            <div>
                              <p
                                className={"secondST-description"}
                                style={{
                                  color:
                                    activeIndex === indexOfFirstItem + index
                                      ? "#FFFFFF"
                                      : "#000000",
                                }}
                              >
                                {nameTit[indexOfFirstItem + index]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </section>
                </div>
              );
            })}
          </div>
          <div className="Cpag">
            <Pagination
              count={Math.ceil(videos.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              className="custom-pagination2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
