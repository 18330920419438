import React, { useEffect, useState } from "react";

import Footer from "../../components/footer";
import Head from "./Head";

const Others = () => {
  const [videoSrc, setVideoSrc] = useState(
    "https://www.youtube.com/embed/-vjG02BbVKM"
  );

  const handleVideoChange = (newSrc: string) => {
    setVideoSrc(newSrc);
  };

  useEffect(() => {
    if (window.location.hash === "#top") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div>
      <Head />
      <Footer />
    </div>
  );
};

export default Others;
