import React from "react";
import { motion } from "framer-motion";

export default function Footer() {
  return (
    <div className={"config-footer"}>
      <div className={"posTit"}>
        <div className={"mainLogo"}>
          <span style={{ color: "rgba(140, 140, 140, 1)" }}>
            뉴테크 인재 양성 프로그램
          </span>
          <img src="./Icons/mz.svg" alt="" />
        </div>
        <div className="first-foot-title">
          <span className="footer-about-project">
            <b>이용약관</b> | <b>개인정보취급방침</b> | <b>법적고지</b>
          </span>
          <div className="footer-address-span">
            <span>
              주소 |{" "}
              <a href="https://m.map.kakao.com/actions/searchView?q=%EB%8C%80%EA%B5%AC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%8F%99%EA%B5%AC%20%EB%8F%99%EB%8C%80%EA%B5%AC%EB%A1%9C%20469%20%EB%8C%80%EA%B5%AC%EC%BD%98%ED%85%90%EC%B8%A0%EB%B9%84%EC%A6%88%EB%8B%88%EC%8A%A4%EC%84%BC%ED%84%B0%20408%ED%98%B8%2C%20410%ED%98%B8&wxEnc=XQPUPP&wyEnc=NQLSSP&lvl=4#!/XQPUQU,NQLSUN/map/bus">
                대구광역시 동구 동대구로 469 대구콘텐츠비즈니스센터 408호, 410호
              </a>
            </span>
          </div>
          <div className={"lastTit"}>
            <div style={{ display: "flex", gap: "10px" }}>
              <span
                style={{
                  color: "rgba(140, 140, 140, 1)",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                이메일 |
              </span>
              <a
                style={{ textDecoration: "none" }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=mannerday@melaka.co.kr"
              >
                <span
                  style={{
                    color: "rgba(140, 140, 140, 1)",
                    fontSize: "15px",
                    fontWeight: 550,
                    display: "flex",
                  }}
                >
                  mannerday@melaka.co.kr
                </span>
              </a>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <span
                style={{
                  color: "rgba(140, 140, 140, 1)",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                대표전화 |
              </span>
              <motion.a
                style={{
                  textDecoration: "none",
                  color: "rgba(140, 140, 140, 1)",
                }}
                href="#"
                whileHover={{ color: "black" }} // Change to desired color on hover
                transition={{ duration: 0.5 }} // Smooth transition effect
              >
                <span
                  style={{
                    color: "rgba(140, 140, 140, 1)",
                    fontSize: "15px",
                    fontWeight: 550,
                    display: "flex",
                  }}
                >
                  070-4760-2215
                </span>
              </motion.a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
