import React from "react";

export function Emoticon() {
  return (
    <div className={"emo-config"}>
      <div className={"mainemo"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className={"con-img"}
        >
          <img src="./Emoticon/1.png" alt="" draggable={false} />
          <img src="./Emoticon/36.png" alt="" draggable={false} />
          <img src="./Emoticon/3.png" alt="" draggable={false} />
          <img src="./Emoticon/4.png" alt="" draggable={false} />
        </div>
        <div className={"con-img"}>
          <img src="./Emoticon/5.png" alt="" draggable={false} />
          <img src="./Emoticon/6.png" alt="" draggable={false} />
          <img src="./Emoticon/7.png" alt="" draggable={false} />
          <img src="./Emoticon/8.png" alt="" draggable={false} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className={"con-img"}
        >
          <img src="./Emoticon/9.png" alt="" draggable={false} />
          <img src="./Emoticon/10.png" alt="" draggable={false} />
          <img src="./Emoticon/11.png" alt="" draggable={false} />
          <img src="./Emoticon/12.png" alt="" draggable={false} />
        </div>
        <div className={"con-img"}>
          <img src="./Emoticon/13.png" alt="" draggable={false} />
          <img src="./Emoticon/14.png" alt="" draggable={false} />
          <img src="./Emoticon/4.png" alt="" draggable={false} />
          <img src="./Emoticon/15.png" alt="" draggable={false} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
          className={"con-img"}
        >
          <img src="./Emoticon/17.png" alt="" draggable={false} />
          <img src="./Emoticon/18.png" alt="" draggable={false} />
          <img src="./Emoticon/19.png" alt="" draggable={false} />
          <img src="./Emoticon/20.png" alt="" draggable={false} />
        </div>

        <div className={"con-img"}>
          <img src="./Emoticon/25.png" alt="" draggable={false} />

          <img src="./Emoticon/27.png" alt="" draggable={false} />
          <img src="./Emoticon/28.png" alt="" draggable={false} />
        </div>
        <div className={"con-img"}>
          <img src="./Emoticon/29.png" alt="" draggable={false} />
          <img src="./Emoticon/30.png" alt="" draggable={false} />
          <img src="./Emoticon/31.png" alt="" draggable={false} />
          <img src="./Emoticon/32.png" alt="" draggable={false} />
        </div>
        <div className={"con-img"}>
          <img src="./Emoticon/33.png" alt="" draggable={false} />
          <img src="./Emoticon/34.png" alt="" draggable={false} />
          <img src="./Emoticon/35.png" alt="" draggable={false} />
          <img src="./Emoticon/36.png" alt="" draggable={false} />
        </div>
      </div>
    </div>
  );
}
