import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"; // Import Menu Icon
import { IconButton } from "@mui/material"; // Import Material UI Icon Button

export function NavbarHome() {
  const [showLinks, setShowLinks] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeLink, setActiveLink] = useState("#home-navbar");
  const [menuOpen, setMenuOpen] = useState(false);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShowLinks(false);
    } else {
      setShowLinks(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  const handleSetActiveLink = (link: React.SetStateAction<string>) => {
    setActiveLink(link);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div id="home-navbar">
      <motion.div
        className="config-navbar"
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: showLinks ? 1 : 0, y: showLinks ? 0 : -50 }}
        transition={{ duration: 0.3 }}
      >
        <div className="config-navbar2">
          <div className="con-icon">
            <Link
              to="#home-navbar"
              smooth
              onClick={() => handleSetActiveLink("#home-navbar")}
            >
              <img src="../Icons/mz.svg" alt="" />
            </Link>
          </div>

          <div className="menu-icon">
            <IconButton onClick={toggleMenu}>
              <MenuRoundedIcon className="menu-rounded-icon-nav" />
            </IconButton>
          </div>

          <div className={`con-link ${menuOpen ? "menu-open" : ""}`}>
            <Link
              className={`main-link ${
                activeLink === "#home-navbar" ? "active" : ""
              }`}
              to="#home-navbar"
              smooth
              onClick={() => handleSetActiveLink("#home-navbar")}
            >
              소개
            </Link>
            <Link
              className={`main-link ${
                activeLink === "/all-videos" ? "active" : ""
              }`}
              to="/all-videos"
              onClick={() => handleSetActiveLink("/all-videos")}
            >
              학생작품
            </Link>
            <Link
              className={`main-link ${
                activeLink === "/other-page" ? "active" : ""
              }`}
              to="/other-page"
              onClick={() => handleSetActiveLink("/other-page")}
            >
              수상작품
            </Link>
          </div>
        </div>
      </motion.div>
      <div className="headPage"></div>
      <div className="headPage2">
        <div className="card-for-link">
          <div className="survey-test-span">
            <span>
              <b>MEDIA NEWTECH FAIR 2024</b>
              <br /> Al 크리에이티브 스토리텔러 챌린지
            </span>
          </div>
          <div className="two-btn-style-link">
            <div className="btn-link-container-style">
              <span>
                루키 리그 <br />
                초4~6
              </span>
              <button
                onClick={() =>
                  (window.location.href = "https://forms.gle/UomDKygJy5898mvM7")
                }
                className="animated-btn"
              >
                신청 바로가기 <span className="arrow">&#x2192;</span>
              </button>
            </div>
            <div className="btn-link-container-style">
              <span>
                주니어 리그 <br />
                중고등1~3
              </span>
              <button
                onClick={() =>
                  (window.location.href = "https://forms.gle/gJfDA4SBPV6yd9mB7")
                }
                className="animated-btn"
              >
                신청 바로가기 <span className="arrow">&#x2192;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
