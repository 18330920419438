import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const videos = [
  "https://www.youtube.com/embed/MFDQPK8olSk",
  "https://www.youtube.com/embed/7I-grCb0Fcw",
  "https://www.youtube.com/embed/r_Wq-rjFoN0",
  "https://www.youtube.com/embed/a3LQDx9xmYo",
  "https://www.youtube.com/embed/NsiVALU2IbE",
  "https://www.youtube.com/embed/Fk56XpRmWZU",
  "https://www.youtube.com/embed/Jbfpkza4TX0",
  "https://www.youtube.com/embed/I8LYXFBfFfM",
  "https://www.youtube.com/embed/5fdn4IL5k9U",
  "https://www.youtube.com/embed/c04rnJRtHlw",
  "https://www.youtube.com/embed/wBe4O-6yaes",
  "https://www.youtube.com/embed/xqUYaM27FdA",
];
const name = [
  "[초등고학년 대상]",
  "[초등고학년 우수상]",
  "[초등고학년 우수상]",
  "[초등고학년 우수상]",
  "[초등고학년 최우수상]",
  "[초등고학년 최우수상]",
  "[초등저학년 대상]",
  "[초등저학년 우수상]",
  "[초등저학년 우수상]",
  "[초등저학년 우수상]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[율원초등학교_5학년]",
];

const scrollTop = [
  "/scrollTop/1.PNG",
  "/scrollTop/2.PNG",
  "/scrollTop/3.PNG",
  "/scrollTop/4.PNG",
  "/scrollTop/5.PNG",
  "/scrollTop/6.PNG",
  "/scrollTop/7.PNG",
  "/scrollTop/8.PNG",
  "/scrollTop/9.PNG",
  "/scrollTop/10.PNG",
  "/scrollTop/11.PNG",
  "/scrollTop/12.PNG",
  "/scrollTop/13.PNG",
  "/scrollTop/14.PNG",
  "/scrollTop/15.PNG",
  "/scrollTop/16.PNG",
  "/scrollTop/17.PNG",
  "/scrollTop/18.PNG",
  "/scrollTop/19.PNG",
  "/scrollTop/20.PNG",
  "/scrollTop/21.PNG",
  "/scrollTop/22.PNG",
  "/scrollTop/23.PNG",
];
const scrollBottom = [
  "/scrollBottom/1.PNG",
  "/scrollBottom/2.PNG",
  "/scrollBottom/3.PNG",
  "/scrollBottom/4.PNG",
  "/scrollBottom/5.PNG",
  "/scrollBottom/6.PNG",
  "/scrollBottom/7.PNG",
  "/scrollBottom/8.PNG",
  "/scrollBottom/9.PNG",
  "/scrollBottom/10.PNG",
  "/scrollBottom/11.PNG",
  "/scrollBottom/12.PNG",
  "/scrollBottom/13.PNG",
  "/scrollBottom/14.PNG",
  "/scrollBottom/15.PNG",
  "/scrollBottom/16.PNG",
  "/scrollBottom/17.PNG",
  "/scrollBottom/18.PNG",
  "/scrollBottom/19.PNG",
  "/scrollBottom/20.PNG",
  "/scrollBottom/21.PNG",
  "/scrollBottom/22.PNG",
  "/scrollBottom/23.PNG",
  "/scrollBottom/24.PNG",
  "/scrollBottom/25.PNG",
  "/scrollBottom/26.PNG",
  "/scrollBottom/27.PNG",
  "/scrollBottom/28.PNG",
  "/scrollBottom/29.PNG",
  "/scrollBottom/30.PNG",
  "/scrollBottom/31.PNG",
  "/scrollBottom/32.PNG",
  "/scrollBottom/33.PNG",
  "/scrollBottom/34.PNG",
  "/scrollBottom/35.PNG",
  "/scrollBottom/36.PNG",
];

const titN = [
  "이★연",
  "이★운",
  "정★린",
  "최★솔",
  "김★연",
  "박★향",
  "김★린",
  "서★아",
  "이★윤",
  "정★민",
];

const thumbnailsPerPageDefault = 4;

const Competition: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbnailStartIndex, setThumbnailStartIndex] = useState(0);
  const [direction, setDirection] = useState(0); // 1 for next, -1 for prev
  const navigate = useNavigate();
  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(
    thumbnailsPerPageDefault
  );

  const handleVideoClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleMoreButtonClick = () => {
    navigate("/other-page#top");
  };

  const handleNext2 = () => {
    setDirection(1);
    setThumbnailStartIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % videos.length;
      return newIndex;
    });
  };

  const handlePrev2 = () => {
    setDirection(-1);
    setThumbnailStartIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + videos.length) % videos.length;
      return newIndex;
    });
  };

  const getThumbnails2 = () => {
    const thumbnails = [];
    for (let i = 0; i < thumbnailsPerPage; i++) {
      thumbnails.push(videos[(thumbnailStartIndex + i) % videos.length]);
    }
    return thumbnails;
  };

  const variants = {
    enter: (direction: number) => ({
      opacity: 0,
      x: direction > 0 ? 100 : -100,
    }),
    center: {
      opacity: 1,
      x: 0,
    },
    exit: (direction: number) => ({
      opacity: 0,
      x: direction > 0 ? -100 : 100,
    }),
  };
  useEffect(() => {
    const updateThumbnailsPerPage = () => {
      if (window.innerWidth <= 450) {
        setThumbnailsPerPage(3); // For mobile screens (450px or below)
      } else {
        setThumbnailsPerPage(4); // Default for larger screens
      }
    };

    updateThumbnailsPerPage();

    window.addEventListener("resize", updateThumbnailsPerPage);

    return () => {
      window.removeEventListener("resize", updateThumbnailsPerPage);
    };
  }, []);
  return (
    <div className="com-config">
      <img
        style={{ position: "absolute", left: "5%", marginTop: "20%" }}
        src="./Icons/com.png"
        alt=""
        className="sun-dis-none"
      />
      <img
        style={{ position: "absolute", right: "5%", marginTop: "10%" }}
        src="./Icons/com2.png"
        alt=""
        className="sun-dis-none"
      />
      <div className={"comMain"}>
        <div className={"vidTitle2"}>
          <div>
            <img src="./Icons/mztitle2.svg" alt="" />
          </div>
          <span
            style={{ fontSize: "20px", fontWeight: "500", marginBottom: "9px" }}
          >
            뉴테크 공모전 수상자들의 영상을 볼 수 있어요!
          </span>
        </div>
        <div className="vid-main-wrapper clearfix">
          <div className="vid-container">
            <div className="main-iframe-video">
              <iframe
                id="vid_frame"
                src={videos[activeIndex]} // Set to the active video in the array
                style={{
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
                allowFullScreen
                scrolling="no"
                allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>

        <section
          id="extra-wrapper"
          style={{
            width: "69%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={"thumbVid"}>
            <motion.button
              title="Next"
              onClick={handlePrev2}
              className="swiper-custom-prev"
              id="swiper-border-remove"
              whileHover={{
                backgroundColor: "#FF7A00",
                border: "1px solid #ffffff",
              }}
            >
              <div
                style={{
                  width: "90px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NavigateBeforeRoundedIcon
                  component={motion.svg}
                  initial={{ color: "#FF7A00" }}
                  whileHover={{ color: "#ffffff" }}
                  className="prev-next-youtube-thumbnail"
                />
              </div>
            </motion.button>
            <Swiper
              className="swiper-container swiper-container-videos slider-produtos-destaque"
              slidesPerView={thumbnailsPerPage}
              spaceBetween={3} // Set gap between slides to 20px
              navigation={false}
              pagination={false}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
            >
              <AnimatePresence initial={false} custom={direction}>
                {getThumbnails2().map((url, index) => (
                  <SwiperSlide key={index} className="swiper-slide">
                    <motion.a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleVideoClick(index);
                        setActiveIndex(
                          (thumbnailStartIndex + index) % videos.length
                        );
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        textAlign: "center",
                        boxSizing: "border-box",
                      }}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      variants={variants}
                      transition={{ duration: 0.3 }}
                      custom={direction}
                    >
                      <div
                        style={{
                          backgroundImage: `url(./youtube2/${
                            ((thumbnailStartIndex + index) % videos.length) + 1
                          }.png)`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        className="thumbnail-video-image-youtube"
                      />
                      <div
                        className={`smallTitle ${
                          activeIndex ===
                          (thumbnailStartIndex + index) % videos.length
                            ? "active"
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            activeIndex ===
                            (thumbnailStartIndex + index) % videos.length
                              ? "#FF7A00"
                              : "white",
                        }}
                      >
                        <p
                          className="desc"
                          style={{
                            color:
                              activeIndex ===
                              (thumbnailStartIndex + index) % videos.length
                                ? "#ffffff"
                                : "#000000",
                          }}
                        >
                          {name[(thumbnailStartIndex + index) % name.length]}
                        </p>
                        <div>
                          <p
                            className="respon-cen-p"
                            style={{
                              color:
                                activeIndex ===
                                (thumbnailStartIndex + index) % videos.length
                                  ? "#ffffff"
                                  : "#000000",
                            }}
                          >
                            {titN[(thumbnailStartIndex + index) % titN.length]}
                          </p>
                        </div>
                      </div>
                    </motion.a>
                  </SwiperSlide>
                ))}
              </AnimatePresence>
            </Swiper>
            <motion.button
              title="Next"
              onClick={handleNext2}
              className="swiper-custom-next"
              id="swiper-border-remove"
              whileHover={{
                backgroundColor: "#FF7A00",
                border: "1px solid #ffffff",
              }}
            >
              <motion.div
                style={{
                  width: "90px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NavigateNextRoundedIcon
                  component={motion.svg}
                  initial={{ color: "#FF7A00" }}
                  whileHover={{ color: "#ffffff" }}
                  className="prev-next-youtube-thumbnail"
                />
              </motion.div>
            </motion.button>
          </div>
        </section>
        <div className={"btnCom"}>
          <motion.button
            style={{ cursor: "pointer" }}
            whileHover={{ scale: 1.1 }}
            onClick={handleMoreButtonClick}
          >
            더 많은 작품 보기
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default Competition;
