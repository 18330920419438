import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const videos = [
  "https://www.youtube.com/embed/5GHPWL9A9uA",
  "https://www.youtube.com/embed/3fvqQhEfX0c",
  "https://www.youtube.com/embed/VQC0eeHLrt4",
  "https://www.youtube.com/embed/0mTfN96c0tw",
  "https://www.youtube.com/embed/lQ-07AYJoLM",
  "https://www.youtube.com/embed/rk1oE6ZXFL4",
  "https://www.youtube.com/embed/kMyMr-moEjs",
  "https://www.youtube.com/embed/yJ6pfcwtQTE",
  "https://www.youtube.com/embed/TK_xGcVkVUM",
  "https://www.youtube.com/embed/916aAI9kYy8",
  "https://www.youtube.com/embed/AHnUJtFqNYI",
  "https://www.youtube.com/embed/_j0aGqxOW4g",
  "https://www.youtube.com/embed/QvDDqyvFh-U",
  "https://www.youtube.com/embed/-vjG02BbVKM",
  "https://www.youtube.com/embed/wBe4O-6yaes",
  "https://www.youtube.com/embed/xqUYaM27FdA",
];

const name = [
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[아양초등학교_5학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[호산초등학교_6학년]",
  "[율원초등학교_5학년]",
];

const scrollTop = [
  "/scrollTop/1.PNG",
  "/scrollTop/2.PNG",
  "/scrollTop/3.PNG",
  "/scrollTop/4.PNG",
  "/scrollTop/5.PNG",
  "/scrollTop/6.PNG",
  "/scrollTop/7.PNG",
  "/scrollTop/8.PNG",
  "/scrollTop/9.PNG",
  "/scrollTop/10.PNG",
  "/scrollTop/11.PNG",
  "/scrollTop/12.PNG",
  "/scrollTop/13.PNG",
  "/scrollTop/14.PNG",
  "/scrollTop/15.PNG",
  "/scrollTop/16.PNG",
  "/scrollTop/17.PNG",
  "/scrollTop/18.PNG",
  "/scrollTop/19.PNG",
  "/scrollTop/20.PNG",
  "/scrollTop/21.PNG",
  "/scrollTop/22.PNG",
  "/scrollTop/23.PNG",
];
const scrollBottom = [
  "/scrollBottom/1.PNG",
  "/scrollBottom/2.PNG",
  "/scrollBottom/3.PNG",
  "/scrollBottom/4.PNG",
  "/scrollBottom/5.PNG",
  "/scrollBottom/6.PNG",
  "/scrollBottom/7.PNG",
  "/scrollBottom/8.PNG",
  "/scrollBottom/9.PNG",
  "/scrollBottom/10.PNG",
  "/scrollBottom/11.PNG",
  "/scrollBottom/12.PNG",
  "/scrollBottom/13.PNG",
  "/scrollBottom/14.PNG",
  "/scrollBottom/15.PNG",
  "/scrollBottom/16.PNG",
  "/scrollBottom/17.PNG",
  "/scrollBottom/18.PNG",
  "/scrollBottom/19.PNG",
  "/scrollBottom/20.PNG",
  "/scrollBottom/21.PNG",
  "/scrollBottom/22.PNG",
  "/scrollBottom/23.PNG",
  "/scrollBottom/24.PNG",
  "/scrollBottom/25.PNG",
  "/scrollBottom/26.PNG",
  "/scrollBottom/27.PNG",
  "/scrollBottom/28.PNG",
  "/scrollBottom/29.PNG",
  "/scrollBottom/30.PNG",
  "/scrollBottom/31.PNG",
  "/scrollBottom/32.PNG",
  "/scrollBottom/33.PNG",
  "/scrollBottom/34.PNG",
  "/scrollBottom/35.PNG",
  "/scrollBottom/36.PNG",
];

const thumbnailsPerPageDefault = 4;

const VideoGallery: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbnailStartIndex, setThumbnailStartIndex] = useState(0);
  const [direction, setDirection] = useState(0); // 1 for next, -1 for prev
  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(
    thumbnailsPerPageDefault
  );
  const navigate = useNavigate();

  const handleVideoClick = (url: string) => {
    const videoFrame = document.getElementById(
      "vid_frame"
    ) as HTMLIFrameElement;
    if (videoFrame) {
      videoFrame.src = url;
    }
  };

  const handleMoreButtonClick2 = () => {
    navigate("/all-videos#top");
  };

  const handleNext = () => {
    setDirection(1);
    setThumbnailStartIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % videos.length;
      return newIndex;
    });
  };

  const handlePrev = () => {
    setDirection(-1);
    setThumbnailStartIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + videos.length) % videos.length;
      return newIndex;
    });
  };

  const getThumbnails = () => {
    const thumbnails = [];
    for (let i = 0; i < thumbnailsPerPage; i++) {
      thumbnails.push(videos[(thumbnailStartIndex + i) % videos.length]);
    }
    return thumbnails;
  };

  const variants = {
    enter: (direction: number) => ({
      opacity: 0,
      x: direction > 0 ? 100 : -100,
    }),
    center: {
      opacity: 1,
      x: 0,
    },
    exit: (direction: number) => ({
      opacity: 0,
      x: direction > 0 ? -100 : 100,
    }),
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();

  // Animation function for the left scrolling
  useEffect(() => {
    const scrollLeft = async () => {
      await controls1.start({
        x: ["0%", "-100%"],
        transition: { duration: 35, repeat: Infinity, ease: "linear" },
      });
    };
    scrollLeft();
  }, [controls1]);

  useEffect(() => {
    const scrollRight = async () => {
      await controls2.start({
        x: ["-100%", "0%"],
        transition: { duration: 40, repeat: Infinity, ease: "linear" },
      });
    };
    scrollRight();
  }, [controls2]);
  useEffect(() => {
    const updateThumbnailsPerPage = () => {
      if (window.innerWidth <= 450) {
        setThumbnailsPerPage(3);
      } else {
        setThumbnailsPerPage(4);
      }
    };

    updateThumbnailsPerPage();

    window.addEventListener("resize", updateThumbnailsPerPage);

    return () => {
      window.removeEventListener("resize", updateThumbnailsPerPage);
    };
  }, []);
  return (
    <div className="vid-config">
      <img
        style={{ position: "absolute", marginLeft: "15%", marginTop: "20px" }}
        src="./Icons/sun.png"
        alt=""
        className="sun-dis-none"
      />
      <img
        style={{ position: "absolute", marginLeft: "120px", marginTop: "30%" }}
        src="./Icons/flower.png"
        alt=""
        className="sun-dis-none"
      />
      <img
        style={{ position: "absolute", right: "5%", marginTop: "20%" }}
        src="./Icons/man.png"
        alt=""
        className="sun-dis-none"
      />
      <div className={"vidTitle2"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center ",
            gap: "9px",
          }}
        >
          <img
            style={{ width: "52px", height: "52px", zIndex: 99 }}
            src="./Icons/mzTable.svg"
            alt=""
          />
          <img
            style={{ width: "100%", height: "36px", zIndex: 99 }}
            src="./Icons/mzTitle.svg"
            alt=""
          />
        </div>
        <span>
          <b>MZ</b> 새싹캠프에 참여한 학생들의 멋진 작품을 볼 수 있어요!
        </span>
      </div>
      <div className="vid-main-wrapper clearfix">
        <div className="vid-container">
          <div className="main-iframe-video">
            <iframe
              id="vid_frame"
              src={videos[activeIndex]}
              style={{
                width: "100%",
                height: "100%",
              }}
              allowFullScreen
              scrolling="no"
              allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </div>

      <section
        id="extra-wrapper"
        style={{
          width: "69%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={"thumbVid"}>
          <motion.button
            title="Next"
            onClick={handlePrev}
            className="swiper-custom-prev"
            whileHover={{
              backgroundColor: "#2F96FD",
              border: "1px solid #ffffff",
            }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NavigateBeforeRoundedIcon
                component={motion.svg}
                initial={{ color: "#2F96FD" }}
                whileHover={{ color: "#ffffff" }}
                className="prev-next-youtube-thumbnail"
              />
            </div>
          </motion.button>
          <Swiper
            className="swiper-container swiper-container-videos slider-produtos-destaque"
            slidesPerView={thumbnailsPerPage}
            spaceBetween={3}
            navigation={false}
            pagination={false}
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
          >
            <AnimatePresence initial={false} custom={direction}>
              {getThumbnails().map((url, index) => {
                // Extract the video ID from the YouTube embed URL
                const videoId = url.split("/").pop();
                // Generate the thumbnail URL
                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

                // Calculate the displayed number starting from 1
                const videoNumber = index + 1;

                return (
                  <SwiperSlide key={index} className="swiper-slide">
                    <motion.a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleVideoClick(url);
                        setActiveIndex(
                          (thumbnailStartIndex + index) % videos.length
                        );
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        textAlign: "center",
                        boxSizing: "border-box",
                      }}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      variants={variants}
                      transition={{ duration: 0.3 }}
                      custom={direction}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${thumbnailUrl})`,
                          backgroundSize: "100%",
                          backgroundPosition: "center",
                        }}
                        className="thumbnail-video-image-youtube"
                      />
                      <div
                        className={`smallTitle ${
                          activeIndex ===
                          (thumbnailStartIndex + index) % videos.length
                            ? "active"
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            activeIndex ===
                            (thumbnailStartIndex + index) % videos.length
                              ? "#2F96FD"
                              : "white",
                        }}
                      >
                        <p
                          className="desc"
                          style={{
                            color:
                              activeIndex ===
                              (thumbnailStartIndex + index) % videos.length
                                ? "#ffffff"
                                : "#000000", // or any other color for non-active
                          }}
                        >
                          {name[(thumbnailStartIndex + index) % name.length]}
                        </p>
                        <div>
                          <p
                            className="desc2"
                            style={{
                              color:
                                activeIndex ===
                                (thumbnailStartIndex + index) % videos.length
                                  ? "#ffffff"
                                  : "#000000", // or any other color for non-active
                            }}
                          >
                            {videoNumber.toString().padStart(3, "0")}{" "}
                            {/* Number from 1 upwards */}
                          </p>
                        </div>
                      </div>
                    </motion.a>
                  </SwiperSlide>
                );
              })}
            </AnimatePresence>
          </Swiper>

          <motion.button
            title="Next"
            onClick={handleNext}
            className="swiper-custom-next"
            whileHover={{
              backgroundColor: "#2F96FD",
              border: "1px solid #ffffff",
            }}
          >
            <motion.div
              style={{
                width: "90px",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NavigateNextRoundedIcon
                component={motion.svg}
                initial={{ color: "#2F96FD" }}
                whileHover={{ color: "#ffffff" }}
                className="prev-next-youtube-thumbnail"
              />
            </motion.div>
          </motion.button>
        </div>
      </section>
      <div className={"btnVid"}>
        <motion.button
          style={{ cursor: "pointer" }}
          onClick={handleMoreButtonClick2}
          whileHover={{ scale: 1.1 }}
        >
          더 많은 작품 보기
        </motion.button>
      </div>
      <div className="wrapPic">
        <motion.div className="wrap1" animate={controls1}>
          <div className="inner-wrap">
            {scrollTop.map((src, index) => (
              <img key={index} src={src} alt={`scrollTop-${index}`} />
            ))}
          </div>
        </motion.div>
        <motion.div className="wrap2" animate={controls2}>
          <div className="inner-wrap">
            {scrollBottom.map((src, index) => (
              <img key={index} src={src} alt={`scrollBottom-${index}`} />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default VideoGallery;
