import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import "swiper/swiper-bundle.css";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"; // Import Menu Icon
import { IconButton } from "@mui/material"; // Import Material UI Icon Button
import "../../../css/conHead.css";

const scrollTop = [
  "/scrollTop/1.PNG",
  "/scrollTop/2.PNG",
  "/scrollTop/3.PNG",
  "/scrollTop/4.PNG",
  "/scrollTop/5.PNG",
  "/scrollTop/6.PNG",
  "/scrollTop/7.PNG",
  "/scrollTop/8.PNG",
  "/scrollTop/9.PNG",
  "/scrollTop/10.PNG",
  "/scrollTop/11.PNG",
  "/scrollTop/12.PNG",
  "/scrollTop/13.PNG",
  "/scrollTop/14.PNG",
  "/scrollTop/15.PNG",
  "/scrollTop/16.PNG",
  "/scrollTop/17.PNG",
  "/scrollTop/18.PNG",
  "/scrollTop/19.PNG",
  "/scrollTop/20.PNG",
  "/scrollTop/21.PNG",
  "/scrollTop/22.PNG",
  "/scrollTop/23.PNG",
];
const scrollBottom = [
  "/scrollBottom/1.PNG",
  "/scrollBottom/2.PNG",
  "/scrollBottom/3.PNG",
  "/scrollBottom/4.PNG",
  "/scrollBottom/5.PNG",
  "/scrollBottom/6.PNG",
  "/scrollBottom/7.PNG",
  "/scrollBottom/8.PNG",
  "/scrollBottom/9.PNG",
  "/scrollBottom/10.PNG",
  "/scrollBottom/11.PNG",
  "/scrollBottom/12.PNG",
  "/scrollBottom/13.PNG",
  "/scrollBottom/14.PNG",
  "/scrollBottom/15.PNG",
  "/scrollBottom/16.PNG",
  "/scrollBottom/17.PNG",
  "/scrollBottom/18.PNG",
  "/scrollBottom/19.PNG",
  "/scrollBottom/20.PNG",
  "/scrollBottom/21.PNG",
  "/scrollBottom/22.PNG",
  "/scrollBottom/23.PNG",
  "/scrollBottom/24.PNG",
  "/scrollBottom/25.PNG",
  "/scrollBottom/26.PNG",
  "/scrollBottom/27.PNG",
  "/scrollBottom/28.PNG",
  "/scrollBottom/29.PNG",
  "/scrollBottom/30.PNG",
  "/scrollBottom/31.PNG",
  "/scrollBottom/32.PNG",
  "/scrollBottom/33.PNG",
  "/scrollBottom/34.PNG",
  "/scrollBottom/35.PNG",
  "/scrollBottom/36.PNG",
];

export default function MainHead() {
  const [showLinks, setShowLinks] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeLink, setActiveLink] = useState("/all-videos");
  const [menuOpen, setMenuOpen] = useState(false); // State to control menu visibility

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShowLinks(false);
    } else {
      setShowLinks(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  const handleSetActiveLink = (link: React.SetStateAction<string>) => {
    setActiveLink(link);
    setMenuOpen(false); // Close the menu after clicking a link
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();

  // Animation function for the left scrolling
  useEffect(() => {
    const scrollLeft = async () => {
      await controls1.start({
        x: ["0%", "-100%"],
        transition: { duration: 45, repeat: Infinity, ease: "linear" },
      });
    };
    scrollLeft();
  }, [controls1]);

  // Animation function for the right scrolling
  useEffect(() => {
    const scrollRight = async () => {
      await controls2.start({
        x: ["-100%", "0%"],
        transition: { duration: 50, repeat: Infinity, ease: "linear" },
      });
    };
    scrollRight();
  }, [controls2]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={"conHead2"}>
      <img
        className="cloud-img-all-video"
        src="./Icons/cloud.svg"
        alt=""
        draggable={false}
      />
      <div>
        <div className="config-navbar">
          <div className="config-navbar2">
            <div className="con-icon">
              <Link
                to="/"
                smooth
                onClick={() => handleSetActiveLink("#home-navbar")}
              >
                <img src="../Icons/mz.svg" alt="" />
              </Link>
            </div>

            <div className="menu-icon">
              <IconButton onClick={toggleMenu}>
                <MenuRoundedIcon className="menu-rounded-icon-main-video" />
              </IconButton>
            </div>

            <div className={`con-link ${menuOpen ? "menu-open" : ""}`}>
              <Link
                className={`main-link ${
                  activeLink === "#home-navbar" ? "active" : ""
                }`}
                to="/"
                smooth
                onClick={() => handleSetActiveLink("#home-navbar")}
              >
                소개
              </Link>
              <Link
                className={`main-link ${
                  activeLink === "/all-videos" ? "active" : ""
                }`}
                to="/all-videos"
                onClick={() => handleSetActiveLink("/all-videos")}
              >
                학생작품
              </Link>
              <Link
                className={`main-link ${
                  activeLink === "/other-page" ? "active" : ""
                }`}
                to="/other-page"
                onClick={() => handleSetActiveLink("/other-page")}
              >
                수상작품
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "none" }} className={"wrapPic"}>
        <motion.div
          style={{ paddingTop: "50px" }}
          className="wrap1"
          animate={controls1}
        >
          <div className="inner-wrap">
            {scrollTop.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`scrollTop-${index}`}
                draggable={false}
              />
            ))}
          </div>
        </motion.div>
        <motion.div className="wrap2" animate={controls2}>
          <div className="inner-wrap">
            {scrollBottom.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`scrollBottom-${index}`}
                draggable={false}
              />
            ))}
          </div>
        </motion.div>
      </div>
      <div className="bottom"></div>
    </div>
  );
}
